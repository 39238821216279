<template>
  <div>
    <!-- Card global infos -->
        <!-- Header -->
        <b-card>
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
            Information du paiement
            </h5>
        </div>

        <!-- BODY -->
        <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
        >
            <!-- Form -->
            <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
            >

            <b-overlay :show="showLoading" no-wrap />
            <b-row>
            <b-col md="6">
                <b-form-group label="Mode de paiement" label-for="mode_de_paiement">
                <b-form-select
                    v-model="compteBancaire.mode_de_paiement"
                    id="mode_de_paiement"
                    disabled
                >
                    <b-form-select-option value="PREV" selected
                    >Prélèvement</b-form-select-option
                    >
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group label-for="periodicite" label="Périodicité">
                    <b-form-select
                    v-model="compteBancaire.periodicite"
                    id="periodicite"
                    disabled
                    >
                    <b-form-select-option value="ANNUEL" selected
                        >Annuelle</b-form-select-option
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>
            </b-row>
            <!-- <b-row>
            <b-col md="12">
                <b-form-group label-for="nom_titulaire" label="Nom du Titulaire">
                    <b-form-input
                    id="nom_titulaire"
                    placeholder="Nom du Titulaire"
                    v-model="compteBancaire.denomination_commercial"
                    disabled
                    />
                </b-form-group>
            </b-col>
            </b-row> -->
            <b-row>
            <b-col md="6">
                <b-form-group label-for="iban" label="IBAN">
                <validation-provider
                    #default="{ errors }"
                    name="IBAN"
                    rules="required"
                >
                    <b-form-input
                    id="iban"
                    placeholder="IBAN"
                    v-model="compteBancaire.iban"
                    :state="
                        errors.length > 0 ||
                        (!isValidIban && compteBancaire.iban != null)
                        ? false
                        : null
                    "
                    />
                    <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                    <small
                    class="text-danger text-lowercase"
                    v-if="!isValidIban && compteBancaire.iban != null && compteBancaire.iban != ''"
                    >veuillez saisir un iban valide</small
                    >
                </validation-provider>
                </b-form-group>
            </b-col>

            <b-col md="6">
                <b-form-group label-for="bic" label="BIC">
                <validation-provider
                    #default="{ errors }"
                    name="BIC"
                    rules="required"
                >
                    <b-form-input
                    id="bic"
                    placeholder="BIC"
                    v-model="compteBancaire.bic"
                    :state="
                        errors.length > 0 ||
                        (!isValidBic && compteBancaire.bic != null)
                        ? false
                        : null
                    "
                    />
                    <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                    <small
                    class="text-danger text-lowercase"
                    v-if="!isValidBic && compteBancaire.bic != null && compteBancaire.bic != ''"
                    >veuillez saisir un bic valide</small
                    >
                </validation-provider>
                </b-form-group>
            </b-col>
            </b-row>


            <!-- Form Actions -->
            <div class="d-flex mt-2">
                <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled='isDisabled'
                >
                Enregistrer
                </b-button>
                
            </div>

            </b-form>
        </validation-observer>
        </b-card>
    
  </div>
</template>

<script>
import {
  VBToggle,
  BRow,
  BCol,
  BButton,
  BCard,
  BCardText,
  BCardHeader,
  BMedia,
  BImg,
  BOverlay,
  BSidebar,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormSelectOption
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    BSidebar,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider, 
    ValidationObserver
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  props: ['courtierData'],
  data() {
    return {
      showLoading: false,
      isValidIban: false,
      isValidBic: false,
      compteBancaire:{
          mode_de_paiement:'PREV',
          periodicite:'ANNUEL',
          denomination_commercial:null,
          iban:null,
          bic:null
      },
      dataCourtier:null,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      currentCourtier: null,
      isDisabled:false,
      required
    };
  },
  watch: {
      'compteBancaire.iban': {
      immediate:true,
      handler(val){
          if(val && val.length > 12){
              this.checkiban(val)
          }
      }
    },
    'compteBancaire.bic': {
      immediate:true,
      handler(val){
        if(val && val.length > 5){
            this.checkbic(val)
        }
      }
    },

  },
  mounted(){
      
        this.currentCourtier = this.currentUser.courtier_user.filter(function(item){
            return item.isPrincipal == 1;       
        })
        this.compteBancaire.denomination_commercial = this.currentCourtier[0].courtier.personne_morale.denomination_commercial
  },
  methods: {
      checkiban(iban){
        this.$http
        .post(`checkiban`, {
          iban: this.compteBancaire.iban
        })
        .then((r) => {
          this.isValidIban = r.data.response;
        }).catch(err => {
          console.log(err)
        })
    },
    checkbic(bic){
        this.$http
        .post(`checkbic`, {
          bic: this.compteBancaire.bic
        })
        .then((r) => {
          this.isValidBic = r.data.response;
        }).catch(err => {
          console.log(err)
        })
    },
    onSubmit(){
      this.$refs.refFormObserver.validate()
            .then(success => {
                this.isDisabled = true
              this.$http.post(`courtier/paymentInformations`, {
              paiement: this.compteBancaire,
              courtier: this.currentCourtier[0].courtier.id
              })
              .then((r) => {
                // console.log(r.data.response);
                this.messageToast(r.data.message, 'Succès', 'success', 'InfoIcon')
                this.isDisabled = false
              }).catch(err => {
                console.log(err)
                this.isDisabled = false
              })
            })
    },
    resetForm(){
        Object.keys(this.compteBancaire).forEach(function(obj,key) {
            console.log(key);
            obj = null
        });

    }
  }
};
</script>

<style></style>
